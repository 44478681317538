<template>
  <div class="get-employee-page">
    <page-cover :cover="cover" v-if="cover" />
    <Nurses />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import Nurses from "@/components/nurses/Nurses.vue";
import { mapGetters } from "vuex";

export default {
  name: "GetEmployee",
  computed: {
    ...mapGetters({
      cover: "pageCover/getNursePage",
    }),
  },
  components: {
    PageCover,
    Nurses,
  },
};
</script>
