<template>
  <div class="get-employee">
    <div class="container">
      <div class="row">
        <transition
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
          mode="out-in"
        >
          <div v-if="!formSended" key="form" class="col-12">
            <div class="team-item team-item--simple">
              <div class="team-item__inner p-5">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.name"
                        :class="{
                          invalid: $v.form.name.$invalid && $v.form.name.$dirty,
                        }"
                        :label="Name"
                        @blur="$v.form.name.$touch()"
                        @input="$v.form.name.$touch()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.surname"
                        :class="{
                          invalid:
                            $v.form.surname.$invalid && $v.form.surname.$dirty,
                        }"
                        :label="Surname"
                        @blur="$v.form.surname.$touch()"
                        @input="$v.form.surname.$touch()"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.email"
                        :class="{
                          invalid:
                            $v.form.email.$invalid && $v.form.email.$dirty,
                        }"
                        :label="Email"
                        @blur="$v.form.email.$touch()"
                        @input="$v.form.email.$touch()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.phone"
                        :class="{
                          invalid:
                            $v.form.phone.$invalid &&
                            $v.form.phone.$dirty,
                        }"
                        :label="Phone"
                        @blur="$v.form.phone.$touch()"
                        @input="$v.form.phone.$touch()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.education"
                        :class="{
                          invalid:
                            $v.form.education.$invalid && $v.form.education.$dirty,
                        }"
                        :label="Education"
                        @blur="$v.form.education.$touch()"
                        @input="$v.form.education.$touch()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <input-row
                        v-model="form.languageSkills"
                        :class="{
                          invalid:
                            $v.form.languageSkills.$invalid && $v.form.languageSkills.$dirty,
                        }"
                        :label="LanguageSkills"
                        @blur="$v.form.languageSkills.$touch()"
                        @input="$v.form.languageSkills.$touch()"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <textarea-row
                        :label="Note"
                        v-model="form.comment"
                        :class="{
                          invalid:
                            $v.form.comment.$invalid && $v.form.comment.$dirty,
                        }"
                        @blur="$v.form.comment.$touch()"
                        @input="$v.form.comment.$touch()"
                      />
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="accept-terms text-left">
                      <input v-model="form.terms" type="checkbox" class="mr-2">
                      <terms/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="career-form__buttons">
                      <a
                        :disabled="$v.form.$invalid"
                        class="button button--main"
                        href="#"
                        @click.prevent="send"
                      >
                        {{ $store.state.statics.Send }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else key="success" class="col-12">
            <success-message />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";
import SuccessMessage from "@/components/shared/SuccessMessage.vue";
import Terms from "@/components/shared/Terms.vue";
export default {
  data() {
    return {
      selected: "",
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        education: "",
        languageSkills: "",
        comment: "",
        terms: false
      },
      formSended: false
    };
  },
  computed: {
    ...mapState({
      Name: (state) => state.statics.Name,
      Surname: (state) => state.statics.Surname,
      Email: (state) => state.statics.Email,
      Phone: (state) => state.statics.Phone,
      Education: (state) => state.statics.Education,
      LanguageSkills: (state) => state.statics.LanguageSkills,
      Note: (state) => state.statics.Note
    })
  },
  methods: {
    send() {
      this.$store.dispatch("nurses/send", this.form).then((res) => {
        if (res) {
          this.formSended = true;
        }
      });
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      surname: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        minLength: minLength(3),
        email
      },
      phone: {
        required,
        minLength: minLength(3)
      },
      education: {
        required,
        minLength: minLength(3)
      },
      languageSkills: {
        required,
        minLength: minLength(3)
      },
      comment: {
        required,
        minLength: minLength(3)
      },
      terms: {
        checked(val) {
          return this.form.terms ? true : val;
        }
      }
    }
  },
  components: {
    SuccessMessage,
    Terms
  }
};
</script>
